<template>
  <div>
    <ValidationObserver ref="reportManager">
      <b-card>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <validation-provider #default="{ errors }" name="Chọn mẫu báo cáo" rules="required">
                <h5 class for>
                  Chọn mẫu Báo cáo
                  <span class="text-danger">*</span>
                </h5>

                <v-select
                  :reduce="option=>option.value"
                  placeholder="Chọn mẫu báo cáo"
                  v-model="reportType"
                  label="label"
                  :clearable="true"
                  :options="REPORT_OPTIONS"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <feather-icon icon="ChevronDownIcon" size="20" />
                    </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] ? 'Vui lòng chọn mẫu Báo cáo' : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <validation-provider #default="{ errors }" name="Chọn Kho" rules="required">
                <h5 class for>
                  Chọn Kho
                  <span class="text-danger">*</span>
                </h5>
                <SelectInventory v-model="inventoryId" @onChangeInventory="handleChangeInventory" />
                <small class="text-danger">{{ errors[0] ? 'Vui lòng chọn Kho' : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="Từ ngày" rules="required">
                <h5 class for>
                  Từ ngày
                  <span class="text-danger">*</span>
                </h5>

                <b-form-datepicker
                  class="cs-datepicker"
                  locale="vi"
                  id="start-date"
                  placeholder="Từ ngày"
                  v-model="fromDate"
                  :max="maxDate"
                  labelNoDateSelected="Bạn chưa chọn ngày"
                  labelHelp="Sử dụng phím con trỏ để điều hướng ngày"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] ? 'Vui lòng ngày bắt đầu' : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="Đến ngày" rules="required">
                <h5 class for>
                  Đến ngày
                  <span class="text-danger">*</span>
                </h5>
                <b-form-datepicker
                  class="cs-datepicker"
                  locale="vi"
                  id="end-date"
                  placeholder="Đến ngày"
                  :min="minDate"
                  :max="maxDate"
                  v-model="toDate"
                  labelNoDateSelected="Bạn chưa chọn ngày"
                  labelHelp="Sử dụng phím con trỏ để điều hướng ngày"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] ? 'Vui lòng chọn ngày kết thúc' : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-col md="12" class="d-flex justify-content-end">
          <b-button @click="handleViewReport" variant="primary">Xem Báo cáo</b-button>
        </b-col>
      </b-card>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { REPORT_OPTIONS } from "@/utils/constant";
import vSelect from "vue-select";
import SelectInventory from "@/components/SelectInventory/index.vue";

export default {
  name: "ReportManager",
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    SelectInventory
  },
  data() {
    return {
      required,
      reportType: null,
      inventoryId: null,
      inventories: [],
      REPORT_OPTIONS,
      fromDate: null,
      toDate: null,
      isLoadingSelect: false
    };
  },
  created() {},
  computed: {
    minDate() {
      const minDate = new Date(this.fromDate);
      return minDate;
    },
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);
      return maxDate;
    }
  },
  methods: {
    async handleViewReport() {
      this.$refs.reportManager.validate().then(success => {
        if (success) {
          const fromDate = moment(this.fromDate)
            .startOf("day")
            .add(7, "hours")
            .valueOf();
          const toDate = moment(this.toDate)
            .endOf("day")
            .add(7, "hours")
            .valueOf();

          this.$router.push({
            name: "ReportDetail",
            params: { id: this.reportType },
            query: {
              inventoryId: this.inventoryId,
              fromDate,
              toDate
            }
          });
        }
      });
    },
    handleChangeInventory(data) {
      this.inventoryId = data.id;
    }
  }
};
</script>

<style lang="scss" scoped>
.cs-datepicker {
  z-index: 20;
}
</style>