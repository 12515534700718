var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"reportManager"},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Chọn mẫu báo cáo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',{attrs:{"for":""}},[_vm._v(" Chọn mẫu Báo cáo "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"reduce":function (option){ return option.value; },"placeholder":"Chọn mẫu báo cáo","label":"label","clearable":true,"options":_vm.REPORT_OPTIONS},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}}],null,true),model:{value:(_vm.reportType),callback:function ($$v) {_vm.reportType=$$v},expression:"reportType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'Vui lòng chọn mẫu Báo cáo' : ''))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Chọn Kho","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',{attrs:{"for":""}},[_vm._v(" Chọn Kho "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('SelectInventory',{on:{"onChangeInventory":_vm.handleChangeInventory},model:{value:(_vm.inventoryId),callback:function ($$v) {_vm.inventoryId=$$v},expression:"inventoryId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'Vui lòng chọn Kho' : ''))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Từ ngày","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',{attrs:{"for":""}},[_vm._v(" Từ ngày "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-datepicker',{staticClass:"cs-datepicker",attrs:{"locale":"vi","id":"start-date","placeholder":"Từ ngày","max":_vm.maxDate,"labelNoDateSelected":"Bạn chưa chọn ngày","labelHelp":"Sử dụng phím con trỏ để điều hướng ngày"},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'Vui lòng ngày bắt đầu' : ''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Đến ngày","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',{attrs:{"for":""}},[_vm._v(" Đến ngày "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-datepicker',{staticClass:"cs-datepicker",attrs:{"locale":"vi","id":"end-date","placeholder":"Đến ngày","min":_vm.minDate,"max":_vm.maxDate,"labelNoDateSelected":"Bạn chưa chọn ngày","labelHelp":"Sử dụng phím con trỏ để điều hướng ngày"},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'Vui lòng chọn ngày kết thúc' : ''))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.handleViewReport}},[_vm._v("Xem Báo cáo")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }